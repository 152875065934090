import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GetHelper } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';

import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export type CompanyDeclarationsValues = {
  authorized_attorney_representative: boolean;

  politically_exposed: boolean;
  pepExplain: string;

  pepRelationship: boolean;
  document_number: string;
  name: string;
  bond: string;

  third_party_operation: boolean;
  document_number_person_or_company: string;
  name_or_company_name: string;

  linked_cm_capital: boolean;

  declare_and_authorize: boolean;
  id: number;
};

@Component({
  selector: 'app-signup-unified-company-declarations',
  templateUrl: './signup-unified-company-declarations.component.html',
  styleUrls: ['./signup-unified-company-declarations.component.scss'],
})
export class SignupUnifiedCompanyDeclarationsComponent
  implements OnInit, OnDestroy
{
  form = this.formBuilder.group({
    authorized_attorney_representative: [false, []],

    politically_exposed: [false, []],
    pepExplain: [null, []],

    politically_exposedpepRelationship: [false, []],
    document_number: [null, []],
    name: [null, []],
    bond: [null, []],

    third_party_operation: [false, []],
    document_number_person_or_company: [null, []],
    name_or_company_name: [null, []],

    linked_cm_capital: [false, []],

    declare_and_authorize: [false, [Validators.requiredTrue]],
  });

  data: CompanyDeclarationsValues;

  event: any;
  constructor(
    private formBuilder: FormBuilder,
    private signupService: SignupService,
    private api: ApiService,
    private toast: ToastService
  ) {}

  ngOnDestroy(): void {
    this.event.unsubscribe();
  }

  ngOnInit(): void {
    this.event = this.signupService.sendCompanyData.subscribe((value) => {
      if (value === 'declarations') {
        this.sendData();
      }
    });

    this.getData();

    this.form.statusChanges.subscribe((status) => {
      this.signupService.setCompanyFormStatus(
        'declarations',
        status === 'VALID'
      );
    });

    this.form.controls.third_party_operation.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.document_number_person_or_company.setValidators([
          Validators.required,
        ]);
        this.form.controls.name_or_company_name.setValidators([
          Validators.required,
        ]);
      } else {
        this.form.controls.document_number_person_or_company.setValue(null);
        this.form.controls.name_or_company_name.setValue(null);
        this.form.controls.document_number_person_or_company.clearValidators();
        this.form.controls.name_or_company_name.clearValidators();
      }
      this.form.controls.document_number_person_or_company.updateValueAndValidity();
      this.form.controls.name_or_company_name.updateValueAndValidity();
    });

    this.form.controls.politically_exposed.valueChanges.subscribe((value) => {
      if (value) {
        this.form.controls.document_number.setValidators([Validators.required]);
        this.form.controls.name.setValidators([Validators.required]);
        this.form.controls.bond.setValidators([Validators.required]);
      } else {
        this.form.controls.document_number.setValue(null);
        this.form.controls.name.setValue(null);
        this.form.controls.bond.setValue(null);
        this.form.controls.bond.clearValidators();
        this.form.controls.name.clearValidators();
        this.form.controls.document_number.clearValidators();
      }
      this.form.controls.document_number.updateValueAndValidity();
      this.form.controls.name.updateValueAndValidity();
      this.form.controls.bond.updateValueAndValidity();
    });
  }

  async getData() {
    try {
      const { data } = await this.api.get({
        route: 'api/registration/declaration_authorization/me',
        token: true,
      });

      this.form.patchValue(data);

      this.data = data;
    } catch (error) {
      console.log(error);
    }
  }

  async sendData() {
    this.signupService.loading = true;

    try {
      const valuesHelper = new GetHelper(this.form.value);

      const payload = {
        authorized_attorney_representative: valuesHelper.get(
          'authorized_attorney_representative'
        ),
        politically_exposed: valuesHelper.get('politically_exposed'),
        pepExplain: valuesHelper.get('pepExplain'),
        politically_exposedpepRelationship: valuesHelper.get(
          'politically_exposedpepRelationship'
        ),
        document_number: valuesHelper.get('document_number'),
        name: valuesHelper.get('name'),
        bond: valuesHelper.get('bond'),
        third_party_operation: valuesHelper.get('third_party_operation'),
        document_number_person_or_company: valuesHelper.get(
          'document_number_person_or_company'
        ),
        name_or_company_name: valuesHelper.get('name_or_company_name'),
        linked_cm_capital: valuesHelper.get('linked_cm_capital'),
        declare_and_authorize: valuesHelper.get('declare_and_authorize'),
      };

      if (this.data && this.data.declare_and_authorize) {
        await this.api.put({
          route: `api/registration/declaration_authorization/${this.data.id}/`,
          token: true,
          body: payload,
        });
      } else {
        await this.api.post({
          route: 'api/registration/declaration_authorization/',
          token: true,
          body: payload,
        });
      }

      this.toast.show('info', 'Sucesso', 'Dados salvos com sucesso!');
    } catch (error) {
      console.error(error);

      this.toast.show('info', 'Erro', error.error.message);
    }
    this.signupService.loading = false;
  }
}
