<div class="col-12" *ngIf="loading">
    <div class="row">
        <div class="col-4">
            <ngx-skeleton-loader [theme]="{
                        height: '468px',
                        margin:'0  0 24px'
                    }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader [theme]="{
                        height: '468px',
                        margin:'0'
                      }">
            </ngx-skeleton-loader>
        </div>

        <div class="col-8">
            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0 0 24px'
                    }">
            </ngx-skeleton-loader>

            <ngx-skeleton-loader [theme]="{
                        height: '244px',
                        margin:'0'
                      }">
            </ngx-skeleton-loader>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading">
    <div class="col-12 col-lg-4">
        <div class="dash-details-card">
            <div class="dash-details-card-header">
                <span>Dados básicos</span>

                <ng-container *ngIf="filteredFiles.basic_info">
                    <app-attachment-button [slugArr]="filteredFiles.basic_info"
                        (onSelect)="handleSelect($event,'basic_info')">
                    </app-attachment-button>
                </ng-container>
            </div>
            <app-spacer [bottom]="15"></app-spacer>

            <ng-container *ngIf="approval.register.type === 'PJ' && isFund">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data da fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    | date: 'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Forma de constituição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    GIIN
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.giin ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.contract_type_display
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae?.name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Patrimônio líquido
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.net_worth ?
                                    formatBrl(approval.register.company.net_worth)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="divider"></div>
                        <app-spacer [bottom]="16"></app-spacer>


                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Autoriza a transmissão de ordens por
                                    procurador ou represetante?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.authorized_attorney_representative
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Possuí relacionamento com pessoa
                                    politicamente exposta?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.politically_exposed
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.document_number
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Vínculo
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.bond
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Opera por conta de terceiros?
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.third_party_operation
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF / CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.document_number_person_or_company
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome da pessoa ou razão social
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.name_or_company_name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    A empresa, seus sócios e/ou representantes é
                                    pessoa vinculada à Banvox
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{declarations?.linked_cm_capital
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>


            <ng-container
                *ngIf="approval.register.type === 'PJ' && isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    |
                                    date:
                                    'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae?.name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    capital social
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.capital ?
                                    formatBrl(approval.register.company.capital)
                                    : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    faturamento bruto anual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.company.yearly_income)
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?
                                    getEmployeesName(approval.register.company.number_employees)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="(approval.register.type === 'PJ' && role === 'assignor' || approval.register.type === 'PJ' && role === 'shareholder') && !isSimplified && !isFund">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document?.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    |
                                    date:
                                    'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Forma de constituição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Isento de inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.free_state_registration
                                    ? 'Sim': 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6"
                            *ngIf="role === 'assignor'">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-subtitle">
                                    GIIN
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.giin ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.contract_type ?
                                    renderContractType(approval.register.company.contract_type):
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company?.contact_email"
                                        target="_blank">
                                        {{approval.register.company.contact_email
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.company.site"
                                        target="_blank">
                                        {{approval.register.company.site &&
                                        approval.register.company.site !== ' ' ?
                                        approval.register.company.site :
                                        '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae ?
                                    approval.register.company.cnae.name :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    PORTE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.company_size_display
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?
                                    getEmployeesName(approval.register.company.number_employees)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    faturamento bruto anual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.yearly_income ?
                                    formatBrl(approval.register.company.yearly_income)
                                    : '--'}}
                                </span>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    capital social
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.capital ?
                                    formatBrl(approval.register.company.capital)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <ng-container *ngIf="role === 'shareholder'">
                            <div class="divider"></div>
                            <app-spacer [bottom]="16"></app-spacer>


                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Autoriza a transmissão de ordens por
                                        procurador ou represetante?
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.authorized_attorney_representative
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Possuí relacionamento com pessoa
                                        politicamente exposta?
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.politically_exposed
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.document_number
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Nome
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.name
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Vínculo
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.bond
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Opera por conta de terceiros?
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.third_party_operation
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF / CNPJ
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.document_number_person_or_company
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Nome da pessoa ou razão social
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.name_or_company_name
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        A empresa, seus sócios e/ou
                                        representantes é
                                        pessoa vinculada à Banvox
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.linked_cm_capital
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>


                    </div>
                </div>

                <div class="divider"></div>
                <app-spacer [bottom]="30"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    coobrigação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor?.joint_obligation
                                    === "true"
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor?.relationship_institution
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Em recuperação judicial
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.judicial_recovery
                                    ? 'Sim' : 'Não'}}
                                    <ng-container
                                        *ngIf="approval.register.company.judicial_recovery_date">
                                        ,
                                        {{approval.register.company.judicial_recovery_date
                                        | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Emite boleto
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.billet_emitter ?
                                    'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Emite NFe
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nfe_emitter ?
                                    'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Emite duplicata
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.duplicate_emitter
                                    ? 'Sim' : 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <!-- <div class="col-12" *ngIf="approval.register.company.judicial_recovery">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.judicial_recovery_date | date: 'dd/MM/yyyy' ?? '--'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Data da homologação
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.assignor.authorization_bacen_queries ? 'Sim' : 'Não'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Autoriza {{'brand.name' | theme}} efetuar consulta no BACEN
                                </span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PJ' && role === 'provider'">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNPJ
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.document.number
                                    ?
                                    formatCnpj(approval.register.company.document.number)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nome Fantasia
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.brand_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de fundação
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.foundation_date
                                    |
                                    date:
                                    'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Forma de constituição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.branch_display
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Natureza jurídica
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.framework?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição municipal
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.municipal_registration
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.state_registration
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Isento de inscrição estadual
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.free_state_registration
                                    ? 'Sim': 'Não'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NIRE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.nire ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column" *ngIf="!isFund">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de contrato
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.contract_type ?
                                    renderContractType(approval.register.company.contract_type):
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.cellphone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.cellphone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.company.phone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.company.phone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.contact_email
                                        ??
                                        '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.company.contact_email"
                                        target="_blank">
                                        {{approval.register.company.site &&
                                        approval.register.company.site !== ' ' ?
                                        approval.register.company.site :
                                        '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CNAE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.cnae?.name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    PORTE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.company_size_display
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    N.º de funcionários
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.company.number_employees
                                    ?
                                    getEmployeesName(approval.register.company.number_employees)
                                    :
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && role !== 'provider' &&  !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatCpf(approval.register.person.document)
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>


                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nacionalidade
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.nationality ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '--'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('marital_choices',approval.register.person.marital_status)?.label
                                    ?? '--'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF DO CÔNJUGE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.spouse &&
                                    approval.register.person.spouse.document
                                    ?formatCpf(approval.register.person.spouse.document)
                                    : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO CÔNJUGE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.spouse?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    REGIME DE CASAMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.spouse &&
                                    approval.register.person.spouse.marriage_bond
                                    ?
                                    getChoicesName('marital_statutes',approval.register.person.spouse.marriage_bond)?.label
                                    : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.mother_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.father_name ??
                                    '--'}}

                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">

                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.secondary_document?.type.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Número do documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.secondary_document
                                    &&
                                    approval.register.person.secondary_document.number
                                    ?
                                    handleFormatDocument(approval.register.person.secondary_document)
                                    : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Órgão expedidor
                                </span>

                                <span class="dash-details-card-item-title">
                                    <ng-container
                                        *ngIf="approval.register.person.secondary_document.uf">
                                        {{approval.register.person.secondary_document.uf}}
                                        /
                                    </ng-container>
                                    {{approval.register.person.secondary_document.dispatching_agency
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de expedição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.secondary_document.expedition_date
                                    |
                                    date: 'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Celular
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.cellphone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.person.cellphone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Telefone
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'tel:'+approval.register.person.phone?.number"
                                        target="_blank">
                                        {{formatPhone(approval.register.person.phone?.number)
                                        ?? '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Email
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="'mailto:'+approval.register.person.email"
                                        target="_blank">
                                        {{approval.register.person.email ??
                                        '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.person.site"
                                        target="_blank">
                                        {{approval.register.person.site &&
                                        approval.register.person.site !== ' ' ?
                                        approval.register.person.site :
                                        '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Rendimento dos últimos 12 meses
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.person.revenues)
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <ng-container *ngIf="role === 'shareholder'">
                            <div class="divider"></div>
                            <app-spacer [bottom]="16"></app-spacer>


                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Autoriza a transmissão de ordens por
                                        procurador ou represetante?
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.authorized_attorney_representative
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Possuí relacionamento com pessoa
                                        politicamente exposta?
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.politically_exposed
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.document_number
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Nome
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.name
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Vínculo
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.bond
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Opera por conta de terceiros?
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.third_party_operation
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        CPF / CNPJ
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.document_number_person_or_company
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        Nome da pessoa ou razão social
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.name_or_company_name
                                        ?? '--'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="d-flex flex-column">
                                    <span
                                        class="dash-details-card-item-subtitle">
                                        A empresa, seus sócios e/ou
                                        representantes é
                                        pessoa vinculada à Banvox
                                    </span>

                                    <span class="dash-details-card-item-title">
                                        {{declarations?.linked_cm_capital
                                        ? 'Sim' : 'Não'}}
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="divider"></div>
                <app-spacer [bottom]="30"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    RELACIONAMENTO COM OUTRAS INSTITUIÇÕES
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person?.assignor?.relationship_institution
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && role === 'provider' &&  !isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.number
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nacionalidade
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.nationality ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE EXPEDIÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.expedition_date
                                    | date:'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '--'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('marital_choices',approval.register.person.marital_status)?.label
                                    ?? '--'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF DO CÔNJUGE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person?.spouse?.document
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO CÔNJUGE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person?.spouse?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    REGIME DE CASAMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person?.spouse &&
                                    approval.register.person.spouse.marriage_bond
                                    ?
                                    getChoicesName('marital_statutes',approval.register.person.spouse.marriage_bond)?.label
                                    : '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.mother_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.father_name ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Tipo de documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.secondary_document?.type?.name
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Número do documento
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.secondary_document
                                    &&
                                    approval.register.person.secondary_document.number
                                    ?
                                    handleFormatDocument(approval.register.person.secondary_document):
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Órgão expedidor
                                </span>

                                <span class="dash-details-card-item-title">
                                    <ng-container
                                        *ngIf="approval.register.person.secondary_document.uf">
                                        {{approval.register.person.secondary_document.uf}}/
                                    </ng-container>
                                    {{approval.register.person.secondary_document.dispatching_agency
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Data de expedição
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.secondary_document.expedition_date
                                    |
                                    date: 'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Site
                                </span>

                                <span class="dash-details-card-item-title">
                                    <a [href]="approval.register.person.site"
                                        target="_blank">
                                        {{approval.register.person.site &&
                                        approval.register.person.site !== ' ' ?
                                        approval.register.person.site :
                                        '--'}}
                                    </a>
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Faturamento
                                    nos últimos 12 meses
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{formatBrl(approval.register.person.revenues)
                                    ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="approval.register.type === 'PF' && isSimplified">
                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CPF
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.number
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE NASCIMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.birth_date |
                                    date:
                                    'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Nacionalidade
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.nationality ??
                                    '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DOCUMENTO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document?.number
                                    ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    DATA DE EXPEDIÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.person.document.expedition_date
                                    | date:'dd/MM/yyyy' ?? '--'}}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Gênero
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{
                                    getChoicesName('gender_choices',approval.register.person.gender)?.label
                                    ?? '--'
                                    }}
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                        <!--
                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{getChoicesName('marital_choices',
                                    approval.general.marital_status)?.label}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    Estado civil
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->

                        <!-- <ng-container
                            *ngIf="approval.general.marital_status === 2 || approval.general.marital_status === 3 ">
                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '--'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        CPF DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-6">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '--'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        NOME DO CÔNJUGE
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>

                            <div class="col-12 col-md-12">
                                <div class="d-flex flex-column">
                                    <span class="dash-details-card-item-title">
                                        {{approval.spouse.marriage_bond !== '' || approval.spouse.marriage_bond !== null
                                        ? approval.spouse.marriage_bond : '--'}}
                                    </span>

                                    <span class="dash-details-card-item-subtitle">
                                        REGIME DE CASAMENTO
                                    </span>
                                    <app-spacer [bottom]="16"></app-spacer>
                                </div>
                            </div>
                        </ng-container>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.general.mother_name ?? '--'}}
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DA MÃE
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    {{approval.general.father_name ?? '--'}}

                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    NOME DO PAI
                                </span>
                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div> -->
                    </div>
                </div>
            </ng-container>
        </div>

        <app-spacer [bottom]="24"></app-spacer>

        <ng-container *ngIf="!isSimplified && !isFund">
            <div class="dash-details-card">
                <div class="dash-details-card-header">
                    <span>Endereço</span>

                    <ng-container *ngIf="filteredFiles.address">
                        <app-attachment-button [slugArr]="filteredFiles.address"
                            (onSelect)="handleSelect($event,'address')">
                        </app-attachment-button>
                    </ng-container>
                </div>
                <app-spacer [bottom]="24"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                CEP
                            </span>

                            <span class="dash-details-card-item-title">
                                {{approval.register.address?.cep ?? '--'}}
                            </span>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                ENDEREÇO COMPLETO
                            </span>

                            <span class="dash-details-card-item-title">
                                {{approval.register.address?.public_place ?
                                approval.register.address?.public_place + ', ' :
                                '--'}}
                                {{approval.register.address?.number ?
                                approval.register.address?.number + ', ' : ''}}
                                {{approval.register.address?.complement ?
                                approval.register.address?.complement + ', ' :
                                ''}}
                                {{approval.register.address?.district ?
                                approval.register.address?.district + ', ' :
                                ''}}
                                {{approval.register.address?.city ?
                                approval.register.address?.city + ' - ' : ''}}
                                {{approval.register.address?.uf ?
                                approval.register.address?.uf : ''}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <app-spacer [bottom]="24"></app-spacer>


        <ng-container *ngIf="isFund">
            <div class="dash-details-card">
                <div class="dash-details-card-header">
                    <span>E-mails de contato do fundo</span>
                </div>
                <app-spacer [bottom]="24"></app-spacer>

                <div class="dash-details-card-body">
                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                Contato principal
                            </span>

                            <span class="dash-details-card-item-title">
                                <ng-container
                                    *ngIf="approval.register.company.contact_email && approval.register.company.contact_email !== 'nan'
                                    && approval.register.company.contact_email !== ' '; else noEmail">
                                    {{approval.register.company.contact_email}}
                                </ng-container>
                                <ng-template #noEmail>
                                    <span>--</span>
                                </ng-template>
                            </span>
                        </div>
                    </div>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="row">
                        <div class="col-12 d-flex flex-column">
                            <span class="dash-details-card-item-subtitle">
                                Contato secundário
                            </span>

                            <span class="dash-details-card-item-title"
                                *ngFor="let email of approval.register.company.secondary_email">
                                {{email}}
                            </span>

                            <ng-container
                                *ngIf="!approval.register.company.secondary_email || approval.register.company.secondary_email.length === 0">
                                <span>--</span>
                            </ng-container>
                            <app-spacer [top]="16"></app-spacer>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="col-12 col-lg-8">
        <div class="row">
            <div class="col-12">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Contas bancárias</span>

                        <ng-container *ngIf="filteredFiles.bank_account">
                            <app-attachment-button
                                [slugArr]="filteredFiles.bank_account"
                                (onSelect)="handleSelect($event,'bank_account')">
                            </app-attachment-button>
                        </ng-container>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>


                    <app-table-list [columns]="bankColumns" [data]="bankData"
                        *ngIf="bankData.length">
                    </app-table-list>

                    <ng-container *ngIf="bankData && bankData.length === 0">
                        <div class="dash-details-card-body">
                            <div class="d-flex justify-content-center">
                                <span>Nenhuma conta bancária cadastrada</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row" [class.flex-row-reverse]="dataCheckForReverseRow()">
            <div class="col-6">
                <div class="col-12 p-0"
                    *ngIf="approval.register.type !== 'PJ' && pepData">
                    <div class="dash-details-card">
                        <div class="dash-details-card-header">
                            <span>Possui relacionamento com PEP</span>
                        </div>
                        <app-spacer [bottom]="25"></app-spacer>

                        <app-table-list [columns]="pepColumns" [data]="pepData">
                        </app-table-list>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12 p-0" *ngIf="role === 'shareholder'">
                    <div class="dash-details-card">
                        <div class="dash-details-card-header"></div>
                        <div
                            class="dash-details-card-body d-flex align-items-center">
                            <!-- <div class="score">
                                36
                            </div>
                            <app-spacer [right]="30"></app-spacer> -->
                            <div class="score-details">
                                <span class="score-title">
                                    {{isFund ? 'Público alvo do fundo cotista'
                                    :'Perfil do investidor'}}
                                </span>
                                <app-spacer [bottom]="5"></app-spacer>
                                <span class="score-label"
                                    *ngIf="approval.register.investor_profile_pj && 
                                    approval.register.investor_profile_pj.length > 0">
                                    {{getRiskLabel(approval.register.investor_profile_pj[0].profile)}}
                                </span>

                                <span class="score-label"
                                    *ngIf="approval.register.investor_profile_pf && approval.register.investor_profile_pf.length > 0">
                                    {{getRiskLabel(approval.register.investor_profile_pf[0].profile)}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>
            </div>

            <div class="col-6" *ngIf="role === 'shareholder'">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Informações fiscais</span>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <div class="dash-details-card-body">
                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca && fatca.citizen_us ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    É cidadão natural, naturalizado ou possui
                                    residência fiscal nos EUA?
                                </span>

                                <span *ngIf="!fatca">
                                    --
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca && fatca.permanent_resident_us ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    É empresa, ou possui partfe de empresa
                                    constituída nos
                                    EUA e que tenha sede/filial permanente no
                                    país?
                                </span>

                                <span *ngIf="!fatca">
                                    --
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca && fatca.has_green_card ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    Possui Visto de Residência Permanente (Green
                                    Card)?
                                </span>

                                <span *ngIf="!fatca">
                                    --
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca && fatca.ssn ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    SSN (Social Security Number)
                                </span>

                                <span class="dash-details-card-item-title"
                                    *ngIf="fatca">
                                    {{fatca.ssn ?? 'Não possuí'}}
                                </span>

                                <span *ngIf="!fatca">
                                    --
                                </span>
                            </div>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex align-items-center">
                            <div class="tax-icon">
                                <i
                                    [class]="fatca && fatca.us_tax_return ? 'fas fa-check' : 'fas fa-times'"></i>
                            </div>
                            <div class="d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    Entrega declaração de imposto de renda e
                                    bens nos EUA
                                    (US Tax Return) por qualquer motivo não
                                    listado?
                                </span>

                                <span class="dash-details-card-item-title"
                                    *ngIf="fatca">
                                    {{fatca.description_us_tax ??
                                    'Não'}}
                                </span>

                                <span *ngIf="!fatca">
                                    --
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <div class="row">
            <div class="col-12"
                *ngIf="approval.register.type === 'PJ'  && !isFund">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Grupo econômico</span>

                        <ng-container *ngIf="filteredFiles.economic_group">
                            <app-attachment-button
                                [slugArr]="filteredFiles.economic_group"
                                (onSelect)="handleSelect($event,'economic_group')">
                            </app-attachment-button>
                        </ng-container>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <app-table-list [columns]="economicGroupColumns"
                        *ngIf="economicGroupData.length > 0"
                        [data]="economicGroupData" size="19px">
                    </app-table-list>

                    <ng-container *ngIf="economicGroupData.length === 0">
                        <div class="dash-details-card-body">
                            <div class="d-flex justify-content-center">
                                <span>Nenhum grupo econômico cadastrado</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="role === 'shareholder'">
                <div class="dash-details-card">
                    <div class="dash-details-card-header">
                        <span>Documentos</span>
                    </div>
                    <app-spacer [bottom]="25"></app-spacer>

                    <div class="table-container">
                        <table>
                            <thead>
                                <tr class="alternative-header">
                                    <th>Arquivo</th>
                                    <th>Emissão</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container
                                    *ngFor="let document of documents">
                                    <tr class="alternative-header"
                                        *ngFor="let item of document.data">
                                        <td style="max-width: 500px;">
                                            {{getDocumentLabel(document.slug)}}
                                        </td>
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{formateDate(item.created_at)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div
                                                class="d-flex align-items-center justify-content-end">
                                                <button
                                                    class="button datasheet-button"
                                                    (click)="openUsPerson(item.file)">
                                                    <i class="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr *ngIf="document.data.length === 0">
                                        <td>
                                            {{getDocumentLabel(document.slug)}}
                                        </td>
                                        <td>--</td>
                                        <td></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <app-spacer [bottom]="28"></app-spacer>
                        </table>
                    </div>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12" *ngIf="isAdmin">
                <div class="dash-details-card card-padding-top">
                    <div class="dash-details-card-body">
                        <div class="row">
                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    CADASTRO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.created_at | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </span>
                            </div>

                            <div class="col-6 d-flex flex-column">
                                <span class="dash-details-card-item-subtitle">
                                    ATUALIZAÇÃO
                                </span>

                                <span class="dash-details-card-item-title">
                                    {{approval.register.updated_at | date:
                                    'dd/MM/yyyy HH:mm'}}
                                </span>
                            </div>

                            <!-- <div class="col-3 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    0*
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    verificação
                                </span>
                            </div>

                            <div class="col-3 d-flex flex-column">
                                <span class="dash-details-card-item-title">
                                    0*
                                </span>

                                <span class="dash-details-card-item-subtitle">
                                    encerremento
                                </span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <app-spacer [bottom]="24"></app-spacer>
</div>

<app-modal #modalRoot class="modal-demo">
    <ng-container class="app-modal-header">
        <ng-container *ngIf="selectedFile">
            <svg-icon src="assets/images/icon_drag_indicator.svg"></svg-icon>
            <div class="text-center">{{modalFiles[0].type.name}}</div>
            <div></div>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-body">
        <ng-container *ngIf="selectedFile">
            <img [src]="selectedFile.url" *ngIf="selectedFile.isImg"
                style="width:100%">

            <iframe [src]="selectedFile.url" *ngIf="!selectedFile.isImg"
                frameborder="0" height="100%" width="100%">
            </iframe>
        </ng-container>
    </ng-container>

    <ng-container class="app-modal-footer">
        <div class="attachment-modal-navigation">
            <button class="attachment-modal-navigation-arrow"
                (click)="setPrevAttachment()" [disabled]="getDisabled('prev')">
                <i class="fas fa-angle-left"></i>
            </button>

            <div class="pl-3 pr-3">{{selectedFile?.name ?
                urlDecoding(selectedFile.name) : ''}}</div>

            <button class="attachment-modal-navigation-arrow"
                (click)="setNextAttachment()" [disabled]="getDisabled('next')">
                <i class="fas fa-angle-right"></i>
            </button>
        </div>
    </ng-container>
</app-modal>