
const gitInfo = {
  branch: "master",
  commit: "f0a1d11",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/f0a1d1191967428837dcc171ab08b9289bdcdc5b",
  date: "07/03/2025",
};

export default gitInfo;
  